import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


function CardPicture(props) {
    
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="300"
        image={props.image}
        alt={`img-${props.name}`}
      />
      <CardContent>
        <Typography gutterBottom variant="h7" component="h7" >
          <strong style={{color:"rgb(213 28 28)"}}>{props.name}</strong><br/>{props.stado}
        </Typography>
        <br/><br/>
        <Typography gutterBottom  variant="subtitle" color="text.secondary" align='justify'>
          {props.body}
        </Typography>
      </CardContent>
    </Card>
  );
}

CardPicture.defaultProps = {
    expanded: true,
    header: true,
    body:'',
    name:'',
    image:'',
    stado:''
}

export default CardPicture;