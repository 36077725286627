import { Component } from "react"
import { css } from '@emotion/react'
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
import CardPicture from '../../elements/CardPicture'

//ICONS MATERIAL UI
import madePilar from '../../../assets/images/footers/testimonios/MadePilar.jpg'
import raulA from '../../../assets/images/footers/testimonios/raulAraiza.jpg'
import AmericaG from '../../../assets/images/footers/testimonios/americaGalo.jpg'
import FaustinoM from '../../../assets/images/footers/testimonios/faustinoMota.jpg'
import PaulinoP from '../../../assets/images/footers/testimonios/paulinoPat.jpg'

class TestimoniosCmp extends Component {    

    render() {
        const {
            data=[
                {
                    image: madePilar,
                    name:'Ma. de Pilar Hernandez ',
                    state:'Oakland',
                    body:'Con el préstamo de Apoyo Financiero inicié mi negocio de venta de tamales y atole para así poder traer más dinero a la casa y solventar todos los gastos de la familia. También pude ayudar a mi hijo con los gastos de su boda y a mi hija le pude pagar el depósito que necesitaba para inscribirse en la Universidad. Nunca olvidaré la cara de felicidad de mis hijos cuando les dije que los apoyaría.',
                },
                {
                    image: raulA,
                    name:'Raúl Araiza',
                    state:'Oakland',
                    body:'Estoy muy agradecido con el préstamo de Apoyo Financiero ya que hice mi sueño realidad de comprar un carro. Estoy muy contento por este favor de Apoyo Financiero.',
                },
                {
                    image: AmericaG,
                    name:'América Galo ',
                    state:'San José',
                    body:'He sido clienta de Apoyo Financiero desde hace ya como tres años. Cinco años atrás hubo unas lluvias fuertes en Nicaragua y lamentablemente la casa de mi mamá no sobrevivió sobre todo el frente de la casa. Apoyo Financiero me dio la oportunidad de construir y finalizar la casa de mi mamá con un 65% de remodelación. Es una excelente empresa que me dio la oportunidad de cumplir uno de mis sueños al mejorar el modo de vida de mi familia que no está en este país.',
                },
                {
                    image: FaustinoM,
                    name:'Faustino Mota',
                    state:'Oakland',
                    body:'Soy cliente de Apoyo Financiero desde hace 8 años. El primer préstamo lo obtuve cuando estaba en San Francisco y lo utilicé para pagar las deudas que tenía en México. El segundo préstamo fue para arreglar mi casa. Apoyo Financiero ha estado conmigo en las buenas y malas. También cuando fallecieron mis padres, pude obtener un préstamo con ellos rápidamente. Gracias al personal de Apoyo Financiero.',
                },
                {
                    image: PaulinoP,
                    name:'Paulino Pat Estrella ',
                    state:'San Francisco',
                    body:'Una de las grandes satisfacciones que Apoyo Financiero me dió fue el abrirme las puertas para poder cumplir parte de mis sueños al venir a este país, ya que nos brinda confianza en préstamos. El primer préstamo lo tomé para construir mi casa; he solicitado varios préstamos los cuales también me han ayudado a darle estudios a mis hijos, uno de ellos se graduó recientemente de chef. La verdad es que me siento muy contento y orgulloso ya que es el único en la familia que ha logrado graduarse. Gracias a Apoyo Financiero por la confianza que me hadado al prestame lo necesario para poder realizar mis sueños y los de mi familia.',
                }
            ],
            titlePrimary='de personas como tú',
            titleIcon='Testimonios',            
      } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4">
                        <strong style={{color:"#698d21"}}>{titleIcon}</strong>                        
                        </Typography>

                        <Typography component='h3' variant="h6" >
                            <strong style={{color:"#e66728"}}>{titlePrimary}</strong>                        
                        </Typography>                                              
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Grid container spacing={2}>
                        {
                            data.map((row,index)=>{
                                return(
                                     <Grid item xs={12} sm={3}>
                                    <CardPicture image={row.image} body={row.body} name={row.name} stado={row.state}/>                    
                                    </Grid>   
                                )
                            })
                        }                        
                        
                    </Grid>
                    

                    </CardElevation>
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

TestimoniosCmp.propTypes = {      
    titlePrimary: PropTypes.string, 
    titleIcon: PropTypes.string, 
    data:PropTypes.object
}

export default withThemeProps(TestimoniosCmp, 'VDPNTestimonios')






